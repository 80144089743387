<template>
  <div id="specialties-section">
    <v-layout row wrap fill-height>
      <v-flex xs12>
        <v-card color="white" :min-height="minHeight" class="py-5">
          <v-flex xs12 sm8 offset-sm2>
            <v-card-title primary-title>
              <h1 id="page-title">{{ title }}</h1>
            </v-card-title>
            <v-card-text>
              <p id="page-body">{{ body }}</p>
            </v-card-text>
          </v-flex>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  data: () => ({ 
    title: 'Our Specialties',
    subtitle: '',
    body: `At Darceo, we specialize in product development through manufacturing implementation and product life-cycle sustainment. This is centered around electromechanical core competencies in the high-reliablility market segment.s Our capability in electronics, mechanicals, and software allow us to leverage market knowledge quickly, advancing our customers' position in their market segements.`
  }),
  props: {
    minHeight: String
  }
}
</script>

<style scoped>
#page-title {
  font-size: 5em;
  color: black;
}
#page-body {
  font-size: 1.5em;
  color: black;
}
</style>