<template>
  <div id="markets-section">
    <Venn :imgs="imgs"/>
  </div>
</template>

<script>
import Venn from '../../layout/Venn.vue'
import ImageStore from '../ImageStore.js'

export default {
  data: () => ({
    imgs: ImageStore.imgs
  }),
  components: {
    Venn
  }
}
</script>
