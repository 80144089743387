<template>
  <div id="venn-diagram">
    <v-card color="secondary" class="pb-5 pt-3">
      <h1 class="text-xs-center">Who We Serve</h1>
      <v-item-group>
        <v-container grid-list-xs>
          <v-layout wrap>
            <v-flex v-for="i in imgs" :key="i.title" xs12 md6>
              <v-item>
                <v-hover>
                  <v-card
                    slot-scope="{ hover }"
                    class="d-flex align-center"
                    dark
                    :height="h"
                    flat
                    :to="i.link"
                  >
                    <v-img 
                      :height="h" 
                      :src="i.src" 
                      gradient="to top, rgba(0,0,0,.45), rgba(0,0,0,0)"
                    >
                      <v-container fill-height fluid pa-2>
                        <v-layout fill-height>
                          <v-flex xs12 align-start flexbox>
                            <span class="d-flex v-card--reveal display-3 white--text" v-text="i.title"></span>
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </v-img>
                    <v-expand-transition color="secondary">
                      <div v-if="hover" class="d-flex transition-fast-in-fast-out v-card--reveal white--text blue-grey darken-3" style="height: 100%;">
                        <v-flex xs8 offset-xs1>
                          <i>{{ i.description }}... READ MORE</i>
                        </v-flex>
                      </div>
                    </v-expand-transition>
                  </v-card>
                </v-hover>
              </v-item>          
            </v-flex>
          </v-layout>
        </v-container>
      </v-item-group>
    </v-card>
  </div>
</template>


<script>
export default {
  data: () => ({ h: '250' }),
  props: [ 'imgs' ]
}
</script>


<style scoped>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.85;
  position: absolute;
  width: 100%;
}
.v-card--title {
  align-items: center;
  bottom: 100;
  justify-content: center;
  position: absolute;
  width: 100%;
}
h1 {
  font-size: 46pt;
  color: white;
}
p {
  font-size: 16pt;
}
</style>