<template>
  <div id="home-page">
    
    <ImgCarousel :images="images"/>
    <Value/>
    <Markets/>
    <specialties/>
    <!-- <Feed/> -->

  </div>
</template>

<script>
import TitleImage from './title_image.vue'
import Value from './sections/Value.vue'
import Specialties from './sections/Specialties.vue'
import CaseStudiesLinks from './case_studies_links.vue'
import ImageStore from './ImageStore.js'
import Markets from './sections/Markets.vue'
// import Feed from './sections/Feed.vue'
import ImgCarousel from '../hero/Carousel.vue'

export default {
  data: () => ({
    images: ImageStore.imgs,
    heroImg: ImageStore.titleImg
  }),
  components: {
    TitleImage,
    Value,
    Specialties,
    CaseStudiesLinks,
    Markets,
    // Feed,
    ImgCarousel
  }
}
</script>
