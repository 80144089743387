<template>
  <!-- eslint-disable -->
  <v-app id="inspire">
    
    <!-- TOOLBAR -->
    <darceo-toolbar></darceo-toolbar>

    <!-- CONTENT (ROUTER) -->
    <v-content>
      <router-view/>      
    </v-content>

    <!-- FOOTER -->
    <darceo-footer/>


  </v-app>
</template>

<script>
import Toolbar from './components/navigation/Toolbar'
import Footer from './components/navigation/Footer'

  export default {
    data: () => ({
      drawer: null
    }),
    props: {
      source: String
    },
    components: {
      'darceo-toolbar' : Toolbar,
      'darceo-footer' : Footer      
    }
  }
</script>