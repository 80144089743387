<template>
  <v-footer
    dark
    height="auto"
  >
    <v-card
      class="flex"
      flat
      tile
    >
      <v-layout column wrap>
        <v-flex xs6>
          <v-card-title class="accent">
            <strong id="footer-message" >{{ footerMsg }}</strong>
            <v-spacer/>

            <v-btn
              v-for="i in items"
              :key="i.action"
              class="mx-3"
              dark
              icon
              :href="i.link"
            >
              <v-icon size="24px">{{ i.action }}</v-icon>
            </v-btn>
          </v-card-title>
        </v-flex>

        <v-flex xs12>
            <v-btn
              flat
              block
              v-for="L in links"
              :key="L.name"
              :to="L.path"
            >
              {{ L.name }}
            </v-btn>
        </v-flex>

        <v-flex xs6 class="py-3">
          <v-card-actions class="grey darken-3 justify-center">
            &copy;{{ copyright_date }} — <strong>&nbsp;{{ company_name }}</strong>
          </v-card-actions>
        </v-flex>
      </v-layout>

    </v-card>
  </v-footer>
</template>

<script>
import PageLinks from '@/stores/PageNavButtonStore.js'
export default {
  data: () => ({
    links: PageLinks.data.links,
    footerMsg: 'Get connected with us on social networks!',
    disclaimer: 'This website is for demonstration purposes only and property of Darceo Inc.',
    company_name : 'DARCEO INC.',
    // copyright_date : '2020',
    items: [
      {
        action: 'mdi-youtube',
        link: 'https://www.youtube.com/channel/UCzdffaYZiEN4mO42Q7nK0Uw'
      },
      {
        action: 'mdi-facebook',
        link: 'https://www.facebook.com/darceoinc/'
      },
      {
        action: 'mdi-twitter',
        link: 'https://twitter.com/darceoinc/'
      },
      {
        action: 'mdi-linkedin',
        link: 'https://www.linkedin.com/company/darceo-inc/'
      }
    ]
  }),

  computed: {
    copyright_date() {
      return new Date().getFullYear();
    },
  },
}
</script>

<style scoped>
  #footer-message {
    font-size: 14pt;
  }
</style>
