<template>
  <div id="sections-value">
    <v-layout row wrap fill-height>
        <v-flex xs12>
          <v-card :min-height="minHeight" class="py-5">
            <v-flex xs12 sm8 offset-sm2>
              <v-card-title primary-title>
                <h1 id="page-title">{{ title }}</h1>
              </v-card-title>
              <v-card-title>
                <h2 id="page-subtitle">{{ subtitle }}</h2>
              </v-card-title>
              <v-card-text>
                <p id="page-body">{{content}}</p>
              </v-card-text>
            </v-flex>
          </v-card>
        </v-flex>
    </v-layout>
  </div>
</template>

<script>
  export default {
    data: () => ({
      minHeight: '300',
      title: 'Our Value',
      subtitle: 'Your Systems Engineering & Integration Partner',
      content: 'Darceo is actively involved in various Industrial markets where product development with sensors and controls leads to improved sales for our customers. Controls systems for large equipment (transportation/mining equipment, factory systems) leverages Darceo experience and expertise to continue product improvement and innovation. The Internet of Things (IoT) devices are driving a new revolution in providing total solutions for the industry and casual user. These devices include smart grid monitoring, thermostats, light bulbs, door locks, industrial monitoring and controls, implants for RFID and other agricultural applications. The concept behind the IoT is all devices working together to provide improved information for improved Life. Darceo has developed several devices in the space leading to a core Embedded internal system that can be leveraged for our customers. This lowers entry costs and builds rapid partnerships in the space for new and upgraded product development projects.'
    })
  }
</script>

<style scoped>
#page-title {
  font-size: 5em;
}
#page-subtitle {
  font-size:2em;
}
#page-body {
  font-size: 1.5em;
}
</style>