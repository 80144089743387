<template>
  <div id="toolbar">
    <v-toolbar fixed app>
      <img :src="handLogo" contain height="45" class="hidden-sm-and-down"/>
      <v-toolbar-title>
        <router-link to="/" id="page-link">
          <img :src="img_src" contain height="18"/>
        </router-link>
      </v-toolbar-title>
      
      <v-spacer/>

    <v-toolbar-items class="hidden-sm-and-down" v-for="L in links" :key="L.name">
      <!-- DROPDOWN -->
      <template v-if="L.name !== 'Home'">
        <template v-if="L.menu == 'true'">
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn 
                  v-on="on"
                  flat
                >
                  {{ L.name }}
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-tile
                  v-for="item in items"
                  :key="item.title"
                  :to="L.path"
                >
                  <v-list-tile-title>{{ item.title }}</v-list-tile-title>
                </v-list-tile>
              </v-list>
          </v-menu>
        </template>
        <!-- NORMAL -->
        <template v-else>
          <v-btn flat :to="L.path">{{ L.name }}</v-btn>
        </template>
      </template>
    </v-toolbar-items>
    <v-menu class="hidden-md-and-up">
      <v-toolbar-side-icon slot="activator"></v-toolbar-side-icon>
      <v-list>
        <v-list-tile v-for="L in links" :key="L.name" :to="L.path">
          <v-list-tile-content>
            <v-list-tile-title>{{ L.name }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-menu>
      
    </v-toolbar>
  </div>
</template>

<script>
import PageLinks from '@/stores/PageNavButtonStore.js'
export default {
  data: () => ({
    img_src : require("@/assets/darceo_logo_title.png"),
    handLogo: require("@/assets/darceo_logo_hand.png"),
    toolbarTitle: '<logo> DARCEO INC.',
    links: PageLinks.data.links,
    items: [
      { title: 'Medical' }, 
      { title: 'Mil/Aero'},
      { title: 'Industrial' },
      { title: 'IoT' }
    ]
  })
}
</script>

<style scoped>
  .page-link, a {
    text-decoration: none;
  }
</style>
