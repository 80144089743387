const PageNavButtonStore = {
  data: {
    links: [
      {
        name: 'Home',
        path: '/',
        menu: 'false'
      },
      {
        name: 'Case Studies',
        path: '/case_studies',
        menu: 'false'
      },
      // {
      //   name: 'Markets',
      //   path: '/markets',
      //   menu: 'true'
      // },
      {
        name: 'Services',
        path: '/services',
        menu: 'false'
      },
      {
        name: 'About',
        path: '/about',
        menu: 'false'
      },
      {
        name: 'Contact',
        path: '/contact',
        menu: 'false'
      },
      {
        name: 'Careers',
        path: '/careers',
        menu: 'false'
      },
      {
        name: 'Info',
        path: '/info',
        menu: 'false'
      }
    ]
  }
}

export default PageNavButtonStore
