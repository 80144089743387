<template>
  <div id="case-studies-links">
    <v-card min-height="400">
        <h1 class="text-xs-center">{{ sectionTitle }}</h1>      
      <v-container fluid grid-list-xl>
        <v-layout row wrap align-center>
          <v-flex xs12 
            v-for="i in items"
            :key="i.title"
            fill-height
          >
            <v-btn 
              :to="i.path"
              block
              large
              :color="i.color"
            >
              <v-icon left style="color:white">{{i.action}}</v-icon>
              {{i.title}}
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </div>
</template>

<script>
export default {
  data: () => ({
    sectionTitle: 'Case Studies',
    items: [
      {
        title: 'Medical',
        action: 'mdi-stethoscope',
        path: '/case_studies',
        color:'primary'
      },
      {
        title: 'Military & Aerospace',
        action: 'mdi-airplane',
        path: '/case_studies',
        color: 'secondary'
      },
      {
        title: 'Industrial',
        action: 'mdi-robot',
        path: '/case_studies',
        color: 'secondary'
      },
      {
        title: 'Internet of Things (IoT)',
        action: 'mdi-radio-tower',
        path: '/case_studies',
        color: 'primary'
      }
    ]
  })
}
</script>

<style scoped>
h1 {
  font-size: 36pt;
}
</style>
