import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    },
    {
      path: '/case_studies',
      name: 'case_studies',
      component: () => import(/* webpackChunkName: "case_studies" */ './views/CaseStudies.vue')
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import(/* webpackChunkName: "case_studies" */ './views/Contact.vue')
    },
    {
      path: '/services',
      name: 'services',
      component: () => import(/* webpackChunkName: "case_studies" */ './views/Services.vue')
    },
    {
      path: '/info',
      name: 'info',
      component: () => import(/* webpackChunkName: "case_studies" */ './views/Legal.vue')
    },
    {
      path: '/markets',
      name: 'markets',
      component: () => import(/* webpackChunkName: "case_studies" */ './views/Markets.vue')
    },
    {
      path: '/electrical_engineering',
      name: 'electrical_engineering',
      component: () => import(/* webpackChunkName: "electrical_engineering" */ './views/disciplines/ElectricalEngineering.vue')
    },
    {
      path: '/mechanical_engineering',
      name: 'mechanical_engineering',
      component: () => import(/* webpackChunkName: "mechanical_engineering" */ './views/disciplines/Mechanical.vue')
    },
    {
      path: '/firmware_software_engineering',
      name: 'firmware_software_engineering',
      component: () => import(/* webpackChunkName: "firmware_software_engineering" */ './views/disciplines/Software.vue')
    },
    {
      path: '/industrial_automation',
      name: 'industrial_automation',
      component: () => import(/* webpackChunkName: "industrial_automation" */ './views/disciplines/Automation.vue')
    },
    {
      path: '/process_support_logistics',
      name: 'process_support_logistics',
      component: () => import(/* webpackChunkName: "process_support_logistics" */ './views/disciplines/Process.vue')
    },
    {
      path: '/unsubscribe',
      name: 'unsubscribe',
      component: () => import('./views/Unsubscribe.vue')
    },
    {
      path: '/careers',
      name: 'careers',
      component: () => import('./views/Careers.vue')
    }
  ],

  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})
