const ImageStore = {
  titleImg: 'https://images.unsplash.com/photo-1517416451146-496a3e48ab6b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1267&q=80',
  imgs: [
    {
      title: 'Mil/Aero',
      description: 'Darceo is an AS9100D certified contractor for the DoD and other security agencies with roots in the military community and several projects worth of experience.',
      src: 'https://images.jpost.com/image/upload/f_auto,fl_lossy/t_Article2016_ControlFaceDetect/430991',
      link: '/case_studies'
    },
    {
      title: 'Medical',
      description: 'Darceo has years of experience in medical device development, regulatory processes, and manufacturing knowledge base.',
      src: 'https://images.unsplash.com/photo-1513224502586-d1e602410265?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1489&q=80',
      link: '/case_studies'
    },
    {
      title: 'Automation',
      description: 'Darceo designs, manufactures, and integrates material handling and automation systems that will increase production, boost production line quality, and maximize return on investment.',
      src: 'https://images.unsplash.com/photo-1543967708-2418d2e7748c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80',
      link: '/case_studies'
    },
    {
      title: 'IoT',
      description: `Darceo has developed several Internet of Things (IoT) platforms, leading to a unique integration capability across multiple IoT applications.`,
      src: 'https://images.unsplash.com/photo-1451187580459-43490279c0fa?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1352&q=80',
      link: '/case_studies'
    }
  ]
};

export default ImageStore;