<template>
  <HomePage />
</template>

<script>
  import HomePage from '../components/home/HomePage'

  export default {
    components: {
      HomePage
    }
  }
</script>
