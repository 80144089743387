<template>
  <v-img
    dark
    :src="img"
    max-height="600"
    cover
    gradient="to top, rgba(0,0,0,.65), rgba(0,0,0,0)"
    position="bottom 70% center"
  >
    <v-container fill-height fluid pa-2>
      <v-layout
        fill-height
        justify-center
        align-center
      >
      <v-flex xs12 class="text-xs-center">
        <h1 id="home-title">{{ heading }}</h1>
      </v-flex>
      <!-- <v-flex>
        <h4 class="subheading"><i>{{ subheading }}</i></h4>
      </v-flex> -->
      </v-layout>
    </v-container>
  </v-img>
</template>

<script>
export default {
  data: () => ({
    heading: 'Your Electro-Mechanical Experts',
    subheading: 'Professional Engineering Services',
  }),
  props: [ 'img' ]
}
</script>

<style scoped>
#home-title {
  font-size: 60pt;
  color: white;
}
</style>
