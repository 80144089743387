<template>
  <div id="carousel">
    <v-carousel
      :height="heroHeight"
      cycle
      interval="7000"
    >
      <v-carousel-item
        v-for="(img, i) in images"
        :key="i"
        :src="img.src"
      >
      
        <v-container fill-height>
          <v-layout align-center justify-start row wrap>
            <v-flex xs12 md6>
              <!-- TRANSPARENT INFO BOX -->
              <v-card dark color="rgba(0,0,0,0.5)">
                <v-card-title>
                  <h1 :class="{ 'display-3': $vuetify.breakpoint.xs}">{{ img.title }}</h1>
                  <i :class="{ 'body-1': $vuetify.breakpoint.xs}">{{ img.description }}</i>
                </v-card-title>
              </v-card>
            </v-flex>
            <!-- CALL TO ACTION BUTTONS -->
            <v-flex xs12>
              <v-btn :large="btnSize" color="info" round to="/case_studies">Learn more</v-btn>
              <v-btn :large="btnSize" round dark color="secondary" to="/contact">Get Quote</v-btn>
            </v-flex>
          </v-layout>          
        </v-container>        
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
export default {
  data: () => ({ }),
  props: {
    images: Array
  },
  computed: {
    heroHeight () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '450px'; break;
        case 'sm': 
        case 'md': 
        case 'lg': 
        case 'xl': return '600px'; break;
      }
    },
    btnSize () {
      switch (this.$vuetify.breakpoint.name) {
        case 'md': 
        case 'lg': 
        case 'xl': return true;
        default: return false;
      }
    }
  }
}
</script>

<style scoped>
h1 {
  font-size: 80pt;
}
i {
  font-size: 16pt;
}
#subheading {
  font-size: 36pt;
}
</style>
